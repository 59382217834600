import React, { useEffect, useState } from 'react';
import transakSDK from '@transak/transak-sdk'
import Layout from "../components/layout";

const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};

 
const TransakInit = () => {
    useEffect(() => {
        let transak = new transakSDK({
            apiKey: '6c9b283c-ba53-464c-b572-a11fefc73427',  // Your API Key (Required)
            environment: 'PRODUCTION', // STAGING/PRODUCTION (Required)
            defaultCryptoCurrency: 'ETH',
            walletAddress: '', // Your customer wallet address
            themeColor: '000000', // App theme color in hex
            // email: 'puneet.softweb@gmail.com', // Your customer email address (Optional)
            redirectURL: 'https://hedgepay.org',
            hostURL: 'https://hedgepay.org', // Required field
            widgetHeight: '550px',
            widgetWidth: '450px'
        });

        transak.init();

        // To get all the events
        transak.on(transak.ALL_EVENTS, (data) => {
            // console.log(data)
        });

        // This will trigger when the user closed the widget
        transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
            transak.close();
        });

        // This will trigger when the user marks payment is made.
        transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
            transak.close();
        });
    }, []);
    return null;
}


const Transak = () => {
    const { isClient } = useIsClient();
    if(!isClient) {
        return null;
    }
    return (<Layout>
        <TransakInit></TransakInit>
    </Layout>
    );
};

export default Transak;